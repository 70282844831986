import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Header, Icon, Card, Image, Loader, Segment, Container } from 'semantic-ui-react';
import './StaticContent.js'
import {WorkBenifits401k, WorkBenifitsHSA, WorkBenifitsFSA, InsuranceLife, InsuranceHealth} from './StaticContent.js';



const BulletContent = (props) => {
    const id = props.match.params
    console.log(id)
    if (id.type === '401k'){
        var type = WorkBenifits401k
    }
    else if (id.type === 'HSA'){
      var type = WorkBenifitsHSA
    }
    else if (id.type === 'FSA'){
      var type = WorkBenifitsFSA
    }
    else if (id.type === 'Life'){
      var type = InsuranceLife
    }
    else if (id.type === 'Health'){
      var type = InsuranceHealth
    }
    else{
        var type = "Site in progress..."
    }
    

    useEffect(() => {
  
    
    }, [type]); // Update if authState changes
  


   
      return (
  
        <div>
            <Helmet>
                <title>BulletedLife | {id.type}</title>
            </Helmet>
            <Container style={{minHeight: "46em"}}>
              <Header className="titleHead"> {id.type}</Header>
              <div dangerouslySetInnerHTML={{__html: type}}></div> 
              </Container>  
        </div>


      );
    };
    export default BulletContent;