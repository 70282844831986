import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Header, Segment, Step, List } from 'semantic-ui-react';
import '../index.css';

const FSA = () => {
    const [active, setActive] = useState("first");

    useEffect(() => {

    }, [active]);

    const handleClick = (e) => {
        console.log(e); 
        console.log(e.target.offsetParent.id);
        if(e.target.offsetParent.id === ""){
            setActive(e.target.id);
        } 
        else{
            setActive(e.target.offsetParent.id);
        }
        
    }

  return (
  
   <div className="content">
        <Helmet>
                <title>Bulleted Life | FSA Summary</title>
        </Helmet>
       <Header className="titleHead">Flexible Savings Account (FSA)</Header>
       
            <Segment  raised={active === 'first'}  onClick={handleClick} id="first">
                <Header as="h4">Another Great Income Tax Saving Avenue </Header>
            </Segment>
            
            <Segment raised={active === 'second'}  onClick={handleClick} id="second" >
                <Header as="h4">Need to understand...</Header>
                <ul>
                        <li>
                            <b>FSA Health Care:</b> Tax savings account similar to HSA but "use it or loose it"</li>
                        <li>
                            <b>FSA Dependent Care:</b> Independent of health care insurance but provides tax free money for child care</li>
                        <li>
                            <b>Use it or loose it:</b> You have to use any contributions each plan year or the money is gone (unlike an HSA) </li>
                        <li>
                            <b>Eligible Items:</b> The specific items/services that can be bought with HSA Funds without penalty/tax </li>
                       
                </ul>
            </Segment>

            <Segment  raised={active === 'third'}  onClick={handleClick} id="third">
                <Header as="h4">The Break Down</Header>
                <ul >
                    <li >
                        Can participate regardless of health insurance plan</li>
                    <li>
                        Lower taxable income by utilize this account to pay for FSA Eligible Items</li>
                    <li>
                        Tax savings of 30%-40% depending on income, not as good of tax savings as HSA</li>
                    <li>
                        Taken out of your paycheck and then paid back to you via claim(s)</li>
                </ul>
            </Segment>

            <Segment  raised={active === 'forth'}  onClick={handleClick} id="forth">
                <Header as="h4">Things to consider</Header>
                <ul >
                    <li >
                        For FSA Health Care accounts, lots of items apply such as contacts, OTC Medicine, Tampons etc</li>
                    <li>
                        Can have HSA AND FSA "Dependent Care" Accounts (recommended if you are healthy and pay for childcare)</li>
                    <li>
                        FSA "Dependent Care" allows you to pay a certian ammount of daycare, tax free</li>         
                    <li>
                        Can be used to pay friend/relative for child care as well as formal daycare/private school</li>         
                </ul>
            </Segment>
        

   </div>

  );
};

export default FSA;
