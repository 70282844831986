import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Header, Segment, Step, List } from 'semantic-ui-react';
import '../index.css';

const Four01k = () => {
    const [active, setActive] = useState("first");

    useEffect(() => {

    }, [active]);

    const handleClick = (e) => {
        console.log(e); 
        console.log(e.target.offsetParent.id);
        if(e.target.offsetParent.id === ""){
            setActive(e.target.id);
        } 
        else{
            setActive(e.target.offsetParent.id);
        }
        
    }

  return (
  
   <div className="content">
        <Helmet>
                <title>Bulleted Life | 401k Summary </title>
        </Helmet>
       <Header className="titleHead">401k</Header>
       
            <Segment  raised={active === 'first'}  onClick={handleClick} id="first">
                <Header as="h4">Excelent way to build wealth and reduce income tax</Header>
            </Segment>
            
            <Segment raised={active === 'second'}  onClick={handleClick} id="second" >
                <Header as="h4">Need to understand...</Header>
                <ul>
                        <li>
                            <b>Max Contirbution Limit:</b> Most money the IRS will allow you to contirbute in a year</li>
                        <li>
                            <b>Company Match:</b> The amount, if any, that a company will match on your contirbutions to a 401k </li>
                        <li>
                            <b>Traditional Contributions:</b> Money is put in to your 401k BEFORE taxes (tax defered to when you withdrawl) </li>
                        <li>
                            <b>Roth Contributions:</b> Money is put in to your 401k AFTER taxes (taxed upfront, no taxes taken when you withdrawl) </li>
                </ul>
            </Segment>

            <Segment  raised={active === 'third'}  onClick={handleClick} id="third">
                <Header as="h4">Simplistic contirbution plan</Header>
                <ul >
                    <li >
                        Max out HSA first if you have one</li>
                    <li>
                        Contribution at least what the company will match, its free money!</li>
                    <li>
                        Keep working to increase you contirbutions till you reach the Max Contirbution Limit</li>
                </ul>
            </Segment>

            <Segment  raised={active === 'forth'}  onClick={handleClick} id="forth">
                <Header as="h4">Should I choose Roth or Traditional contributions?</Header>
                <ul >
                    <li >
                        Guessing game of "Will my income tax be higher now or later?"</li>
                    <li>
                        Tax brackets may change in the future, choose Roth to avoid this risk</li>
                    <li>
                        However you probably will have lower income in retirement meaning less taxes, choose Tradtional</li>
                    <li>
                        If your income and tax brackets would be the EXACT same today vs when you withdrawl/retire, then it really wouldnt make a difference</li>
                    <li>
                        If your a high income earner now its more likely TRADITIONAL would be best as taxes are DEFERRED </li>
                    <li>
                        If your a lower income earner now its more likely ROTH would be best as taxes are paid NOW/Upfront</li>
                     
                </ul>
            </Segment>
        

   </div>

  );
};

export default Four01k;
