import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './Home.js'
import BulletContent from './BulletContent';
import Health from './Insurance/Health'
import Navbar from './Navbar';
import Footer from './Footer';
import { Container, Grid } from 'semantic-ui-react';
import GoogleAds from './GoolgeAds';
import ScrollToTop from './ScrollToTop';
import HealthInsCalc from './Tools/HealthInsCalc';
import TaxBracketCalc from './Tools/TaxBracketCalc';
import About from './About.js';
import Four01k from './Insurance/Four01k';
import HSA from './Insurance/HSA';
import FSA from './Insurance/FSA';
import LifeInsurance from './Insurance/LifeInsurance';
import HomeBuying from './Insurance/HomeBuying';


const App = () => {
  return (

    <Router>
        <Navbar>
          <ScrollToTop>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/About" exact component={About} />
            <Route path="/HomeOwnership/Buying" exact component={HomeBuying} />
            <Route path="/HomeOwnership/:type" exact component={BulletContent} />
            <Route path="/WorkBenifits/401k" exact component={Four01k} />
            <Route path="/WorkBenifits/HSA" exact component={HSA} />
            <Route path="/WorkBenifits/FSA" exact component={FSA} />
            <Route path="/WorkBenifits/:type" exact component={BulletContent} />
            <Route path="/Insurance/Health" exact component={Health} />
            <Route path="/Insurance/Life" exact component={LifeInsurance} />
            <Route path="/Insurance/:type" exact component={BulletContent} />
            <Route path="/Investing/:type" exact component={BulletContent} />
            <Route path="/Life/:type" exact component={BulletContent} />
            <Route path="/Tools/HealthInsurancePlanComparison" exact component={HealthInsCalc} />
            <Route path="/Tools/TaxBracketCalc" exact component={TaxBracketCalc} />
          </Switch>
          </ScrollToTop>
        </Navbar>
        <GoogleAds slot="7160430488"/>
        <Footer />
        
    </Router>
    

  );
};

export default App;
