import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Icon, Segment, Grid, List, Header, Divider } from 'semantic-ui-react';
import AdComponent from './GoolgeAds';


const Footer = () => {

  return (
    <Segment inverted vertical style={{ margin: '3em 0em 0em', padding: '5em 0em' }} >
      <Container textAlign='center'>
        <Grid divided inverted stackable>
          <Grid.Column width={7}>
            <Header inverted as='h4' content='Bulleted Life' />
            <List link inverted>
              <List.Item as='a'>Tools</List.Item>
              <List.Item as='a'>Blog</List.Item>
              <List.Item><Link to="/About">About</Link></List.Item>
              <List.Item as='a'>Contact Us</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header inverted as='h4' content='Life of Wealth' />
            <p>
              "Create life principles, understand the financial options you have and how they work, create short and long term goals, execute on your goals, live a life of Wealth!"
            </p>
          </Grid.Column>
        </Grid>

        <Divider inverted section />
        <List horizontal inverted divided link size='small'>
          <List.Item as='a' href='#'>
            Site Map
          </List.Item>
          <List.Item as='a' href='#'>
            Contact Us
          </List.Item>
          <List.Item as='a' href='#'>
            Terms and Conditions
          </List.Item>
          <List.Item as='a' href='#'>
            Privacy Policy
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
};
export default Footer;