import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Header, Segment, Step, List } from 'semantic-ui-react';
import '../index.css';

const HomeBuying = () => {
    const [active, setActive] = useState("first");

    useEffect(() => {

    }, [active]);

    const handleClick = (e) => {
        console.log(e); 
        console.log(e.target.offsetParent.id);
        if(e.target.offsetParent.id === ""){
            setActive(e.target.id);
        } 
        else{
            setActive(e.target.offsetParent.id);
        }
        
    }

  return (
  
   <div className="content">
        <Helmet>
                <title>Bulleted Life | Home Buying Summary</title>
        </Helmet>
       <Header className="titleHead">Home Buying</Header>
       
            <Segment  raised={active === 'first'}  onClick={handleClick} id="first">
                <Header as="h4">Buying a Home Can Help Build Wealth</Header>
            </Segment>
            
            <Segment raised={active === 'second'}  onClick={handleClick} id="second" >
                <Header as="h4">Need to understand...</Header>
                <ul>
                        <li>
                            <b>Realator Fees:</b> Fees that are paid to the buying and selling realator, typically paid by the Seller</li>
                        <li>
                            <b>Mortgage Interest:</b> Money the buyer pays to the lender each month for borrowing money to buy the house</li>
                        <li>
                            <b>Escrow:</b> Money collect each month to pay for items like home insurance and property taxes</li>
                        <li>
                            <b>Conventional Loan:</b> less requirements loan but requires min. 5% down payment</li>
                        <li>
                            <b>FHA Loan:</b> More stringent requirements but only requires 3.5% down payment</li>
                
                </ul>
            </Segment>

            <Segment  raised={active === 'third'}  onClick={handleClick} id="third">
                <Header as="h4">The Break Down</Header>
                <ul >
                    <li >
                        Buying a home allows you to build equity via the "principle" part of the mortgage payment</li>
                    <li>
                        Your mortgage payment consist of Principle, Interest, and Escrow</li>
                    <li>
                        While payments on the home stay relitive the same the amount of money that goes to Principle vs Interest will vary</li>
                    <li>
                        Mortgages will be front loaded with interest, meaning your overall balance will go down very minimally in the first years</li>
                    <li>
                        Mortgages interest can be deducted from taxes, reducing taxable income</li>
                    <li>
                        Local banks typically provide lower interest rates, shop around for rates before choosing a lender</li>
                </ul>
            </Segment>

            <Segment  raised={active === 'forth'}  onClick={handleClick} id="forth">
                <Header as="h4">Tips When Thinking About Becoming a Home Owner </Header>
                <ul >
                    <li >
                        Dont buy a house if you plan on selling it in the next 5 years</li>
                    <li>
                        Best to put 5% or more down to get a convential loan over and FHA due to the extra stipulations of an FHA Loan</li>
                    <li>
                        Buy a house that is no more than your family's yearly income X 2 (ex. $100,000 yearly income = $200,000 mortgage or less)</li>           
                    <li>
                        Make extra principle payments to get a guaranteed return, however that money could make more money with other investements (ex stock)</li> 
                    <li>
                        Always check to make sure home is not in a flood zone as flood insurance is expensive and required by most lenders</li>          
                </ul>
            </Segment>
        

   </div>

  );
};

export default HomeBuying;
