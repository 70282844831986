import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Table, Input, Header, Divider, Segment, List, Dropdown, Button, Radio, Popup, Icon} from 'semantic-ui-react'

const TaxBracketCalc = () => {
    const[filingStatus, setFilingStatus] = useState(null);
    const[totalSalary, setTotalSalary] = useState(70000);
    const[showDeductions, setShowDeductions] = useState(false);
    const[deductions, setDeductions] = useState({
        "401k": 0,
        "IRA": 0,
        "HSA": 0,
        "FSA": 0,
        "FSAD": 0
    });
    useEffect(() => {
    

    }, [filingStatus, totalSalary]);

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleStatus = (e) => {
        setFilingStatus(e.target.textContent)
    }

    const handleSalary = (e) => {
        setTotalSalary(e.target.value)
    }

    const handleDeductionsVisibility = () => {
        setShowDeductions(prevDeductions => !prevDeductions);
    }

    const handleDeductions = (e) => {
        
        setDeductions(prevState => ({
            ...prevState,
            [e.target.id] : e.target.value
        }))
            
    }
       
    const FilingStatus = [
        {
          key: 'Single',
          text: 'Single',
          value: 'Single',
      
        },
        {
            key: 'Joint',
            text: 'Joint',
            value: 'Joint',
        }
    ]

    const Taxes = (salary, status) => {

        var tenMax 
        var tweleveMax 
        var twentytwoMax 
        var twentyfourMax 
        var thirtytwoMax 
        var thirtyfiveMax 


        if(status === "Joint"){
            tenMax = 20500
            tweleveMax = 83550
            twentytwoMax = 178150
            twentyfourMax = 340100
            thirtytwoMax = 431900
            thirtyfiveMax = 647850
        }
        else{
            tenMax = 10275
            tweleveMax = 41775
            twentytwoMax = 89075
            twentyfourMax = 170050
            thirtytwoMax = 215950
            thirtyfiveMax = 539900
        }

        var taxable = 0
        var remaining

        if( salary > thirtyfiveMax){

            taxable = parseFloat(salary) - parseFloat(thirtyfiveMax)
            var thirtysevenPercent = taxable * .37
            remaining  = thirtyfiveMax
            
        }
        else{
            var thirtysevenPercent = 0
            remaining = salary
            
        }
        if( remaining > thirtytwoMax){

            taxable = parseFloat(remaining) - parseFloat(thirtytwoMax)
            var thirtyfivePercent = taxable * .35
            remaining = parseFloat(remaining) - parseFloat(taxable)
        }
        else{
            var thirtyfivePercent = 0
            remaining = salary
            
        }
        if( remaining > twentyfourMax){

            taxable = parseFloat(remaining) - parseFloat(twentyfourMax)
            var thirtytwoPercent = taxable * .32
            remaining = parseFloat(remaining) - parseFloat(taxable)
        }
        else{
            var thirtytwoPercent = 0
            remaining = salary
            
        }
        if( remaining > twentytwoMax){

            taxable = parseFloat(remaining) - parseFloat(twentytwoMax)
            var twentyfourPercent = taxable * .24
            remaining = parseFloat(remaining) - parseFloat(taxable)
        }
        else{
            var twentyfourPercent = 0
            remaining = salary
            
        }
        if( remaining > tweleveMax){

            taxable = parseFloat(remaining) - parseFloat(tweleveMax)
            var twentytwoPercent = taxable * .22
            remaining = parseFloat(remaining) - parseFloat(taxable)
        }
        else{
            var twentytwoPercent = 0
            remaining = salary
            
        }
        if( remaining > tenMax){

            taxable = parseFloat(remaining) - parseFloat(tenMax)
            var twelvePercent = taxable * .12
            remaining = parseFloat(remaining) - parseFloat(taxable)
        }
        else{
            var twelvePercent = 0
            remaining = salary
            
        }
       
        taxable = parseFloat(remaining)
        var tenPercent = taxable * .10
    
        var totalTaxes = parseFloat(tenPercent) + parseFloat(twelvePercent) + parseFloat(twentytwoPercent) + parseFloat(twentyfourPercent) + parseFloat(thirtytwoPercent) + parseFloat(thirtyfivePercent) + parseFloat(thirtysevenPercent)

        return {tenPercent, twelvePercent, twentytwoPercent, twentyfourPercent, thirtytwoPercent, thirtyfivePercent, thirtysevenPercent, totalTaxes}

    }

    var Computed = Taxes(totalSalary, filingStatus)
    const sumOfDeductions = Object.values(deductions).reduce((a, b) => parseFloat(a) + parseFloat(b));
    var ComputedwDeductions = Taxes((parseFloat(totalSalary)-parseFloat(sumOfDeductions)), filingStatus)


    return (
        <div >
        <Helmet>
                <title>Bulleted Life | Tax Bracket and Deductions Calc</title>
        </Helmet>
        <Header className="titleHead">Tax Bracket & Deductions Calculator</Header>
        <Segment>
            <Header> Personal Info </Header>
            <table>
                <Table.Row>
                    <Table.HeaderCell>Single or Jointly tax filing</Table.HeaderCell>
                    <Table.Cell>
                        <Dropdown selection options={FilingStatus} onChange={handleStatus} defaultValue={FilingStatus[0].value}/>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>Total Yearly Salary</Table.HeaderCell>
                    <Table.Cell>
                        <Input id="monthlyCost" iconPosition='left' name="A" defaultValue='70000' onChange={handleSalary}>
                        <Icon name='dollar' />
                        <input />
                        </Input>   
                    </Table.Cell>
                </Table.Row>
            </table>
        </Segment>
        <Header>Add Deductions:</Header>
        <Radio toggle onClick={handleDeductionsVisibility}/>
        <div className={showDeductions ? "" : "deductions"}>
            <Segment style={{marginTop: "2em", marginBottom: "2em"}}>
            <Header> Yearly Contributions </Header>
                <table>
                    <Table.Row>
                    <Popup wide inverted content='2022 max per person = $20,500' trigger={<Table.HeaderCell>401k Traditional Contributions</Table.HeaderCell>} />
                        <Table.Cell>
                            <Input id="401k"  iconPosition='left' defaultValue='0' onChange={handleDeductions} >
                            <Icon name='dollar' />
                            <input />
                            </Input>   
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                    <Popup wide inverted content='2022 max per person = $6,000' trigger={<Table.HeaderCell>IRA Traditional Contributions</Table.HeaderCell>} />
                        <Table.Cell>
                            <Input id="IRA"  iconPosition='left' defaultValue='0' onChange={handleDeductions} >
                            <Icon name='dollar' />
                            <input />
                            </Input>   
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                    <Popup wide inverted content='2022 max self-only = $3,650 or family = $7,300' trigger={<Table.HeaderCell>HSA Contributions</Table.HeaderCell>} />
                        <Table.Cell>
                            <Input id="HSA"  iconPosition='left' defaultValue='0' onChange={handleDeductions} >
                            <Icon name='dollar' />
                            <input />
                            </Input>   
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                    <Popup wide inverted content='2022 max per person = $2,850' trigger={<Table.HeaderCell>FSA Contributions</Table.HeaderCell>} />
                        <Table.Cell>
                            <Input id="FSA"  iconPosition='left' defaultValue='0' onChange={handleDeductions} >
                            <Icon name='dollar' />
                            <input />
                            </Input>   
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                    <Popup wide inverted content='2022 max per household = $5,000' trigger={<Table.HeaderCell>FSA DependentCare Contributions</Table.HeaderCell>} />
                        <Table.Cell>
                            <Input id="FSAD" iconPosition='left' defaultValue='0' onChange={handleDeductions} >
                            <Icon name='dollar' />
                            <input />
                            </Input>   
                        </Table.Cell>
                    </Table.Row>
                </table>
            </Segment>
        </div>

        <Divider style={{marginTop: "2em", marginBottom: "2em"}}/>
                <Segment inverted raised>
                    <Header as="h2">  
                       Your total taxes paid will be <br />{formatter.format(Computed.totalTaxes)}
                    <Divider />
                    </Header>
                        <List inverted>
                            <List.Item><List.Header as="h4">Items that could affect the results:</List.Header></List.Item>
                            <List.Item>* Reduce taxable income with 401k/IRA traditional contributions</List.Item>
                            <List.Item>* Reduce taxable income with HSA/FSA contirbution</List.Item>
                            <List.Item>* If you itemize taxes donations can reduce taxable income</List.Item>
                        </List>
                </Segment>

                <div className={showDeductions ? "" : "deductions"}>
                <Segment inverted raised>
                    <Header as="h2">  
                       With Deductions...Your total taxes paid will be <br />{formatter.format(ComputedwDeductions.totalTaxes)}
                    </Header>
                    <Header as="h2" color="green">  
                       Thats a savings of {formatter.format(Computed.totalTaxes - ComputedwDeductions.totalTaxes)}!
                    </Header>
                    <List inverted>
                            <List.Item>* Items like 401k/IRA, the savings are tax deffered</List.Item>
                            <List.Item>* Items like HSA/FSA are true tax savings (if spent on eligible items)!</List.Item>
                        </List>
                </Segment>


                </div>

        <Divider style={{marginTop: "2em", marginBottom: "2em"}}/>

        <Segment inverted raised className="totalTable">
            <Header className="totals">Detailed Breakdown:</Header>
            <table className="results">
            <Table.Row>
                <Table.HeaderCell>Current Tax Bracket (2022)</Table.HeaderCell>
                <Table.Cell> 
                    <Header as="h5" className={showDeductions ? "totals" : "deductions totals"}>  
                       Standard
                    </Header>
                        10%&nbsp;=&nbsp;{formatter.format(Computed.tenPercent)}<br />
                        12%&nbsp;=&nbsp;{formatter.format(Computed.twelvePercent)}<br />
                        22%&nbsp;=&nbsp;{formatter.format(Computed.twentytwoPercent)}<br />
                        24%&nbsp;=&nbsp;{formatter.format(Computed.twentyfourPercent)}<br />
                        32%&nbsp;=&nbsp;{formatter.format(Computed.thirtytwoPercent)}<br />
                        35%&nbsp;=&nbsp;{formatter.format(Computed.thirtyfivePercent)}<br />
                        37%&nbsp;=&nbsp;{formatter.format(Computed.thirtysevenPercent)}<br />
                        <Header className="totals">Total Tax = {formatter.format(Computed.totalTaxes)}</Header>
                </Table.Cell>
                <Table.Cell className={showDeductions ? "" : "deductions"}> 
                    <Header className="totals">  
                       With&nbsp;Deductions
                    </Header>
                        10%&nbsp;=&nbsp;{formatter.format(ComputedwDeductions.tenPercent)}<br />
                        12%&nbsp;=&nbsp;{formatter.format(ComputedwDeductions.twelvePercent)}<br />
                        22%&nbsp;=&nbsp;{formatter.format(ComputedwDeductions.twentytwoPercent)}<br />
                        24%&nbsp;=&nbsp;{formatter.format(ComputedwDeductions.twentyfourPercent)}<br />
                        32%&nbsp;=&nbsp;{formatter.format(ComputedwDeductions.thirtytwoPercent)}<br />
                        35%&nbsp;=&nbsp;{formatter.format(ComputedwDeductions.thirtyfivePercent)}<br />
                        37%&nbsp;=&nbsp;{formatter.format(ComputedwDeductions.thirtysevenPercent)}<br />
                        <Header className="totals">Total&nbsp;Tax&nbsp;= {formatter.format(ComputedwDeductions.totalTaxes)}</Header>
                </Table.Cell>
            </Table.Row>
            
            </table>
        </Segment>
        </div>
    )
}

export default TaxBracketCalc
