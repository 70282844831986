import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Header, Segment, Step, List } from 'semantic-ui-react';
import '../index.css';

const HSA = () => {
    const [active, setActive] = useState("first");

    useEffect(() => {

    }, [active]);

    const handleClick = (e) => {
        console.log(e); 
        console.log(e.target.offsetParent.id);
        if(e.target.offsetParent.id === ""){
            setActive(e.target.id);
        } 
        else{
            setActive(e.target.offsetParent.id);
        }
        
    }

  return (
  
   <div className="content">
        <Helmet>
                <title>Bulleted Life | HSA Summary</title>
        </Helmet>
       <Header className="titleHead">Health Savings Account (HSA)</Header>
       
            <Segment  raised={active === 'first'} onClick={handleClick} id="first">
                <Header as="h4">Best Tax Savings Account Possible!</Header>
            </Segment>
            
            <Segment raised={active === 'second'}  onClick={handleClick} id="second" >
                <Header as="h4">Need to understand...</Header>
                <ul>
                        <li>
                            <b>Eligble Health Plan:</b> Only certian health insurance plans (high deductable) are designated as eligble for HSA account/contributions</li>
                        <li>
                            <b>Tripple Tax Savings:</b> Rare opportunity to not get taxed at all! (not on income, earnings, or withdrawl)</li>
                        <li>
                            <b>Eligible Items:</b> The specific items/services that can be bought with HSA Funds without penalty/tax </li>
                        <li>
                            <b>Roll Over Funds:</b> Funds roll over/accumulate year after year (vs FSA use it or loose it) </li>
                       
                </ul>
            </Segment>

            <Segment  raised={active === 'third'}  onClick={handleClick} id="third">
                <Header as="h4">The One of a Kind Benifits</Header>
                <ul >
                    <li >
                        If eligble, the first account you should start saving in</li>
                    <li>
                        Unlike FSA money rolls over (NOT use it or loose it)</li>
                    <li>
                        Money can be invested allowing for compound intrest</li>
                    <li>
                        Dont use this money, rather pay out of pocket for medical expense and save/compound this tax free account</li>
                    <li>
                        If you never use the money it will  mimic an IRA Traditional account upon retirment age (59 1/2)</li>
                    <li>
                        Account will stay with you if you transition jobs </li>
                </ul>
            </Segment>

            <Segment  raised={active === 'forth'}  onClick={handleClick} id="forth">
                <Header as="h4">Things to consider</Header>
                <ul >
                    <li >
                        Make sure money in the HSA account can be invested</li>
                    <li>
                        Make sure the money in your HSA is invested</li>
                    <li>
                        Max contribution limit is pretty low compared to 401k, IRA etc</li>         
                </ul>
            </Segment>
        

   </div>

  );
};

export default HSA;
