
import { Container, Header, Segment, Step } from 'semantic-ui-react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import './index.css';

const About = (props) => {
    const [active, setActive] = useState(null);

    useEffect(() => {

    }, [active]);



    const handleClick = (e, { title }) => {
        console.log(title);
        setActive(title);
    }
    

    return (
            <div>
            <Helmet>
                <title>Bulleted Life | About</title>
            </Helmet>
            <Container>
                <Header textAlign="center" as='h2' className="aboutHead"> Living life in a bulleted way </Header>
            </Container>
            <Container  textAlign='center' style={{ marginTop: '2em' }}>
                <Step.Group widths={5} size='tiny'>
                    <Step
                    active={active === 'Life Principles'}
                    icon='edit'
                    link
                    onClick={handleClick}
                    title='Life Principles'
                    description='Set the priciples by which you want to live your life'
                    />
                    <Step
                    active={active === 'Financial Options'}
                    icon='balance scale'
                    link
                    onClick={handleClick}
                    title='Financial Options'
                    description='Understand the various options you have to build net worth'
                    />
                    <Step
                    active={active === 'Create Goals'}
                    icon='calendar check outline'
                    link
                    onClick={handleClick}
                    title='Create Goals'
                    description='Create short and long term goals'
                    />
                    <Step
                    active={active === 'Execute & Measure'}
                    icon='signal'
                    link
                    onClick={handleClick}
                    title='Execute & Measure'
                    description='Execute your goals, measure your success, Repeat '
                    />
                    <Step
                    active={active === 'Life of Wealth'}
                    icon='heart'
                    link
                    onClick={handleClick}
                    title='Life of Wealth'
                    description='Live a lower stressed life with confidence in your direction'
                    />
                </Step.Group>
            </Container>
            <Container style={{ marginTop: '2em', minHeight: "32em"}}>
            <div id="ad1">    
            </div>
                <p>
                    These will be the only paragraphs of this entire website. The idea being that in life we really only need snip-its of information, in an efficient manner 
                    to make our own decisions. Reading through an article I only find my self attempting to pull these snip-its of information out and storing them in what little memory 
                    I have free. I also believe "Experts" tend to take you down every rabbit hole, or every possibility imaginable when really I'm looking for the "average"
                    or most likely answers. From there we can all do our own specified research, add our own personal context, and create our own understandings and path forward. 
                </p>
                <p>
                    Good thing I'm not an expert! This site is really to share "rule of thumb" guidance in a very efficient manner. This should get people started on their
                    own journey to understand the basics elements of Money, Wealth Building, Risk Mitigations while balancing Life Goals and Principles
                </p>
                <p>
                    While I truly believe money doesn't bring you happiness or prosperity, I do believe having an understanding of how financials work is very important
                    to achive happyness and prosperity. Finances are the #1 reason for divorce and stress. 
                    As my Dad would always say, "A failure to plan is a plan for failure!". If we understand the basics we can create a plan for 
                    achieving our financial goals. What is many times over looked though and what I would like to stress is we need to ensure they align with our life principles and 
                    life goals. If you have financial goals to have a net worth of 3 million by the age 40, but it cost you your marriage and kids... is that really what you wanted?
                    You might perceive yourself as rich, but are you wealthy?
                </p>
                <p>
                    Create life principles, understand the financial options you have and how they work, create short and long term goals, execute on your goals, live a life of wealth! 
                </p>
            
            </Container>
        </div>
    );
};
export default About;