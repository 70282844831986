
import { Container, Header, Segment, Icon, GridRow, GridColumn, Grid } from 'semantic-ui-react';
import React, { useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import './index.css';
import Background from './Images/calcBG.jpg';


const Home = (props) => {
    const [active, setActive] = useState(null);

    useEffect(() => {

    }, [active]);



    const handleClick = (e, { title }) => {
        console.log(title);
        setActive(title);
    }
    

    return (
        <div>
            <Helmet>
                <title>Bulleted Life | Home</title>
            </Helmet>
            <div className="home">
            <Container style={{paddingTop: '30px'}} >
                <Header textAlign="center" as='h2'><Link style={{"color":"black"}} to="/About"> Bullet Point, Financial Knowledge</Link></Header>
                <Header textAlign="center" as='h2'><Link style={{"color":"black"}} to="/Tools/TaxBracketCalc"> Financial Calculators</Link> </Header>
                <Header textAlign="center" as='h2'><Link style={{"color":"black"}} to="/Tools/TaxBracketCalc">Tax Calculators</Link></Header>
                <Header textAlign="center" as='h2'><Link style={{"color":"black"}} to="/Tools/HealthInsurancePlanComparison">Health Care Calculators</Link></Header>
                <Header textAlign="center" as='h2'><Link style={{"color":"black"}} to="/About"> Step-by-Step Financial Planning</Link></Header>
            </Container>
            </div>
            <div className="homeBody">
            <Container  textAlign='center' >
                <Segment padded="very" >
                    <Header as="h2"  className="homePage" icon>
                        <Icon name='target' />
                        Why read paragraphs when you get the same data from efficient bullet points?
                    </Header>
                </Segment>
            </Container>
            <Container textAlign='center' >
                <Segment padded="very">
                    <Grid columns={2} textAlign='center' >
                        <GridRow>
                            <GridColumn>
                            <Link style={{"color":"black"}} to="/Tools/TaxBracketCalc">
                                <Header as="h3" className="homePage" icon>
                                    <Icon name='line graph' />
                                    Use reactive financial calculators to help you understand and create a financial plan
                                </Header>
                            </Link>
                            </GridColumn>
                            <GridColumn>
                            <Link style={{"color":"black"}} to="/Tools/HealthInsurancePlanComparison">
                                <Header as="h3" className="homePage" icon>
                                <Icon name='hospital outline' />
                                    Health Care plan calculators to help you pick the best cost plan!
                                </Header>
                            </Link>
                            </GridColumn>
                        </GridRow>
                   </Grid>
                </Segment>
            </Container>
            </div>
            <div>
            <Container  textAlign='center' style={{ marginTop: '10em' }}>
                <Header className="quote">
                    "Create life principles, understand the financial options you have and how they work, create short and long term goals, execute on your goals, live a life of Wealth!"
                </Header>
            </Container>
            </div>
            
            
        </div>
    );
};
export default Home;