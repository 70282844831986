import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Header, Segment, Step, List } from 'semantic-ui-react';
import '../index.css';

const LifeInsurance = () => {
    const [active, setActive] = useState("first");

    useEffect(() => {

    }, [active]);

    const handleClick = (e) => {
        console.log(e); 
        console.log(e.target.offsetParent.id);
        if(e.target.offsetParent.id === ""){
            setActive(e.target.id);
        } 
        else{
            setActive(e.target.offsetParent.id);
        }
        
    }

  return (
  
   <div className="content">
        <Helmet>
                <title>Bulleted Life | Life Insurance Summary</title>
        </Helmet>
       <Header className="titleHead">Life Insurance</Header>
       
            <Segment  raised={active === 'first'}  onClick={handleClick} id="first">
                <Header as="h4"> Financialy Protect Those You Love</Header>
            </Segment>
            
            <Segment raised={active === 'second'}  onClick={handleClick} id="second" >
                <Header as="h4">Need to understand...</Header>
                <ul>
                        <li>
                            <b>Life Insurance pays out money in the scenrio where the insured dies while covered under the policy</b></li>
                        <li>
                            <b>Life Insurance is only required when your loved ones could not enjoy they life they live without your salary</b></li>
                
                </ul>
            </Segment>

            <Segment  raised={active === 'third'}  onClick={handleClick} id="third">
                <Header as="h4">The Break Down</Header>
                <ul >
                    <li >
                        Many options and can be very complicated</li>
                    <li>
                        Ask the question "If I died in the next 'X' years would my loved ones be ok?</li>
                    <li>
                        If the answer is "Yes" then no insurance is needed</li>
                    <li>
                        If the answer is "No" keep reading</li>
                </ul>
            </Segment>

            <Segment  raised={active === 'forth'}  onClick={handleClick} id="forth">
                <Header as="h4">Term Life Insurance is recomended and more straight forward </Header>
                <ul >
                    <li >
                        Select Payout ammount and duration of coverage, pay a monthly fee until death, cancelation of policy or duration of term</li>
                    <li>
                        Ex. $1,000,000 payout with a term of 25 years. Pay $75 a month fee. After 25 years pass policy is no longer valid</li>
                    <li>
                        If the insured dies within the 25 years (and up to date on payments) the ammount is paid to the benificiary</li>         
                    <li>
                        Or after 25 years pass policy is no longer valid</li>         
                </ul>
            </Segment>
        

   </div>

  );
};

export default LifeInsurance;
