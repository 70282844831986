import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Header, Segment, Step, List } from 'semantic-ui-react';
import '../index.css';

const Health = () => {
    const [active, setActive] = useState("first");

    useEffect(() => {

    }, [active]);

    const handleClick = (e) => {
        console.log(e); 
        console.log(e.target.offsetParent.id);
        if(e.target.offsetParent.id === ""){
            setActive(e.target.id);
        } 
        else{
            setActive(e.target.offsetParent.id);
        }
        
    }

  return (
  
   <div className="content">
        <Helmet>
                <title>Bulleted Life | Health Insurance Summary</title>
        </Helmet>
       <Header className="titleHead">Health Insurance </Header>
       
            <Segment  raised={active === 'first'}  onClick={handleClick} id="first">
                <Header as="h4">Everyone needs health insurance!</Header>
            </Segment>
            
            <Segment raised={active === 'second'}  onClick={handleClick} id="second" >
                <Header as="h4">Need to understand...</Header>
                <ul>
                        <li>
                            <b>Deductable:</b> Money you will pay until insurance kicks in</li>
                        <li>
                            <b>CoInsurance:</b> After Deductable is satisfied, what percentage you will be responsible for</li>
                        <li>
                            <b>Max out of pocket:</b> The MOST you can pay in one policy year</li>
                        <li>
                            <b>HSA Eligibility:</b> Does this plan qualify me for HSA contirbutions</li>
                </ul>
            </Segment>

            <Segment  raised={active === 'third'}  onClick={handleClick} id="third">
                <Header as="h4">Heath Insurance Plan Options</Header>
                <ul >
                    <li >
                        When choosing, its a balance of risk as well as trying to predict the future (all insurance)</li>
                    <li>
                        Typically high deductable with low paycheck cost or low deductable with High paycheck cost... some options inbetween</li>
                    <li>
                        "Max out of pocket" is something to consider</li>
                    <li>
                        How the insurance breaks up children(s) and spouse's on a policy is another consideration</li>
                </ul>
            </Segment>

            <Segment  raised={active === 'forth'}  onClick={handleClick} id="forth">
                <Header as="h4">High Deductable - Low Cost</Header>
                <ul >
                    <li >
                        Only High deductables policies make you eligible for HSAs</li>
                    <li>
                        Lean this way if you dont go to the doctor often</li>
                    <li>
                        Lean this way if you are younger and have time to build/compound an HSA account</li>
                    <li>
                        If you pick an HSA eligible plan you MUST contribute to it! ...In my opionion</li>
                        <li>
                        Also these polices typically have lower max out of pocket cost</li>
                        <li>
                        Instead of paying say a $25 dollar copay for a general Dr. visit it will be more like $125</li>
                        <li>
                    Perscriptions could cost a bit more until your deductable is reached</li>
                </ul>
            </Segment>

            <Segment  raised={active === 'fifth'}  onClick={handleClick} id="fifth">
                <Header as="h4">Low Deductable - High Cost</Header>
                <ul >
                    <li >
                        Low deductable plans do not allow you to contirbute to an HSA account</li>
                    <li>
                        Lean this way if you go to the doctor often</li>
                    <li>
                        Insurance kicks in sooner but you pay a higher premium per month/paycheck</li>
                    <li>
                        Also these polices typically have high max out of pocket cost which you need to consider</li>
                </ul>
            </Segment>
        

   </div>

  );
};

export default Health;
