import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Table, Input, Header, Divider, Segment, List, Popup, Icon } from 'semantic-ui-react'

const HealthInsCalc = () => {
    const[bestPlan, setBestPlan] = useState(null);

    const [planData, setPlanData] = useState({
        "deducatble": 500,
        "maxOoP": 10000,
        "coIns": 10,
        "monthlyCost": 100
    });

    const [planBData, setPlanBData] = useState({
        "deducatble": 500,
        "maxOoP": 10000,
        "coIns": 10,
        "monthlyCost": 100
    });

    const [estData, setEstData] = useState({
        "drVisits": 10,
        "drVisitCost": 150,
        "specialty": 0
    });


    useEffect(() => {
    
        if (PlanA.totalCost > PlanB.totalCost){
            setBestPlan("B")
        }
        else if (PlanA.totalCost < PlanB.totalCost){
            setBestPlan("A")
        }
        else{
            setBestPlan("equal")
        }

    }, [planData, planBData, estData, bestPlan]);

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });


    const calculate = (planData, estData) => {
        var totalBilledCost = parseFloat(estData.drVisits * estData.drVisitCost) + parseFloat(estData.specialty);
        var metDeducatable = parseFloat(planData.deducatble) <= parseFloat(totalBilledCost) ? "Yes" : "No";
        var metMaxOoP = false
        var premiumCost = planData.monthlyCost * 12;
        var coInsuranceCost 
        if((parseFloat(totalBilledCost) - parseFloat(planData.deducatble))*(planData.coIns*.01) <= 0){
            coInsuranceCost = 0;
        }
        else{
            coInsuranceCost = (parseFloat(totalBilledCost) - parseFloat(planData.deducatble))*(planData.coIns*.01)
            if(parseFloat(coInsuranceCost) + parseFloat(planData.deducatble) >= planData.maxOoP){
                coInsuranceCost = parseFloat(planData.maxOoP) - parseFloat(planData.deducatble)
            }
        }

        var remainingDeductable;
        var deductablePaid
        if((planData.deducatble - totalBilledCost) <= 0)
            {
                remainingDeductable = 0;
                deductablePaid = planData.deducatble
            }
            else{
                remainingDeductable = parseFloat(planData.deducatble) - parseFloat(totalBilledCost);
                deductablePaid = parseFloat(planData.deducatble) - parseFloat(remainingDeductable);
            }

        var outOfPocket;
        if(metDeducatable==="No")
            {
                outOfPocket = totalBilledCost;
            }
        else{
                outOfPocket = parseFloat(planData.deducatble) + (coInsuranceCost);
                if(outOfPocket >= planData.maxOoP)
                {
                    outOfPocket = planData.maxOoP;
                    metMaxOoP = true;
                }
            }
        
        var totalCost = parseFloat(premiumCost) + parseFloat(outOfPocket) ;


        return {premiumCost, totalBilledCost, metDeducatable, remainingDeductable, deductablePaid, coInsuranceCost, outOfPocket, totalCost};

    }


    var PlanA = calculate(planData, estData)
    var PlanB = calculate(planBData, estData);

    const handleChangePD = (e) => {
        var setFunction;
        if (e.target.name === "A")
        {
            setPlanData(prevState => ({
                ...prevState,
                [e.target.id] : e.target.value
            }))
            PlanA = calculate(planData, estData)
            
        }
        if (e.target.name === "B")
        {
            setPlanBData(prevState => ({
                ...prevState,
                [e.target.id] : e.target.value
            }))
            PlanB = calculate(planData, estData)
        }

   
}   

    const handleChangeED = (e) => {
        console.log(e)
        console.log(e.target.value)
        setEstData(prevState => ({
            ...prevState,
            [e.target.id] : e.target.value
        }))
    }

    

    return (
        <div >
            <Helmet>
                <title>Bulleted Life | Health Insurance Comparison</title>
            </Helmet>
            <Header className="titleHead">Health Insurance Plan Comparison</Header>
            <Segment>
                <Header> Plan's Information:</Header>
                <table className="planInfo">
                    <thead>
                    <Table.Row>
                         <Table.HeaderCell>Plan</Table.HeaderCell>
                         <Table.Cell textAlign="center" ><Header>A</Header></Table.Cell>
                         <Table.Cell textAlign="center" ><Header>B</Header></Table.Cell>
                    </Table.Row>
                    </thead>
                    <Table.Row>
                    <Popup wide inverted content='Premium cost you pay for the health plan' trigger={<Table.HeaderCell>Monthly Cost</Table.HeaderCell>} />
                        <Table.Cell>
                            <Input id="monthlyCost" name="A" iconPosition='left' defaultValue={planData.monthlyCost} onChange={handleChangePD}>
                            <Icon name='dollar' />
                            <input />
                            </Input>
                        </Table.Cell>
                        <Table.Cell>
                            <Input id="monthlyCost" name="B" iconPosition='left' defaultValue={planData.monthlyCost} onChange={handleChangePD}>
                                <Icon name='dollar' />
                                <input />
                                </Input>   
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                    <Popup wide inverted content='How much you pay upfront before insurance kicks in' trigger={<Table.HeaderCell>Deductable</Table.HeaderCell>} />
                        <Table.Cell>
                                <Input id="deducatble" name="A" iconPosition='left' defaultValue={planData.deducatble} onChange={handleChangePD}>
                                    <Icon name='dollar' />
                                    <input />
                                    </Input> 
                                </Table.Cell>
                        <Table.Cell>
                            <Input id="deducatble" name="B" iconPosition='left' defaultValue={planData.deducatble} onChange={handleChangePD}>
                            <Icon name='dollar' />
                                <input />
                                </Input>   
                            </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                    <Popup wide inverted content='The percentage of costs you pay after you have paid your deductible' trigger={<Table.HeaderCell>Co-Insurance %</Table.HeaderCell>} />
                        <Table.Cell><Input id="coIns" name="A" defaultValue={planData.coIns} onChange={handleChangePD}/></Table.Cell>
                        <Table.Cell><Input id="coIns" name="B" defaultValue={planData.coIns} onChange={handleChangePD}/></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                    <Popup wide inverted content='The most you have to pay for covered services in a plan year' trigger={<Table.HeaderCell>Max Out Of Pocket</Table.HeaderCell>} />
                        <Table.Cell>
                            <Input id="maxOoP" name="A" iconPosition='left' defaultValue={planData.maxOoP} onChange={handleChangePD}>
                                <Icon name='dollar' />
                                <input />
                                </Input> 
                            </Table.Cell>
                        <Table.Cell>
                            <Input id="maxOoP" name="B" iconPosition='left' defaultValue={planData.maxOoP} onChange={handleChangePD}>
                                <Icon name='dollar' />
                                <input />
                                 </Input> 
                        </Table.Cell>
                    </Table.Row>
                </table>
            </Segment>
            <Segment>
                <Header> Guessing Health Visits (per year):</Header>
                <table className="planInfo">
                    <thead>
                        <Table.Row>
                            <Table.HeaderCell>Num Of Dr Visits</Table.HeaderCell>
                            <Table.HeaderCell>Cost per Dr. Visit (avg)</Table.HeaderCell>
                        </Table.Row>
                    </thead>
                    <Table.Row>
                            <Table.Cell><Input id="drVisits"  defaultValue={estData.drVisits} onChange={handleChangeED}/></Table.Cell>
                            <Table.Cell>
                                <Input id="drVisitCost" iconPosition='left' defaultValue={estData.drVisitCost} onChange={handleChangeED}>
                                    <Icon name='dollar' />
                                    <input />
                                    </Input>   
                                </Table.Cell>
                        </Table.Row>
                </table>

                <Header> Guessing Speciality Visits/Exams/Surgeries (per year):</Header>
                <table>
                    <thead>
                    <Table.Row>
                        <Table.HeaderCell>Total Cost </Table.HeaderCell>
                    </Table.Row>
                    </thead>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <Input id="specialty"  iconPosition='left' defaultValue={estData.specialty} onChange={handleChangeED}>
                                <Icon name='dollar' />
                                    <input />
                                    </Input>   
                                </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </table>
            </Segment>

            <Divider style={{marginTop: "2em", marginBottom: "2em"}}/>
                <Segment inverted raised>
                    <Header as="h2">  
                        {bestPlan === "equal" && (
                        "Your health plans are Equal"
                        )}
                        {bestPlan === "A"  && (
                        "Plan 'A' is your best choice!"
                        )}
                        {bestPlan === "B" && (
                        "Plan 'B' is your best choice!"
                        )}
                    <Divider />
                    </Header>
                        <List inverted>
                            <List.Item><List.Header as="h4">Items that could affect the results:</List.Header></List.Item>
                            <List.Item>* In/Out of network providers (always utilize in-network)</List.Item>
                            <List.Item>* Special cost specific services (Child Birth, Hospice, Mental Health, etc.)
                            </List.Item>
                        </List>
               
                </Segment>
            <Divider style={{marginTop: "2em", marginBottom: "2em"}}/>

            <Segment inverted raised>
                <Header>Detailed Breakdown:</Header>
                <table className="results">
                    <thead>
                    <Table.Row>
                         <Table.HeaderCell> <b>Plan Choice</b></Table.HeaderCell>
                        <Table.Cell className={bestPlan === "A" ? "best" : ""} ><b>Plan 'A'</b></Table.Cell>
                        <Table.Cell className={bestPlan === "B" ? "best" : ""} ><b>Plan 'B'</b></Table.Cell>
                    </Table.Row>
                    </thead>
                    <Table.Row>
                        <Table.HeaderCell>Premium Cost</Table.HeaderCell>
                        <Table.Cell className={bestPlan === "A" ? "best" : ""} >{formatter.format(PlanA.premiumCost)}</Table.Cell>
                        <Table.Cell className={bestPlan === "B" ? "best" : ""} >{formatter.format(PlanB.premiumCost)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Total Billed Cost</Table.HeaderCell>
                        <Table.Cell className={bestPlan === "A" ? "best" : ""} >{formatter.format(PlanA.totalBilledCost)}</Table.Cell>
                        <Table.Cell className={bestPlan === "B" ? "best" : ""} >{formatter.format(PlanB.totalBilledCost)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Met Deductable?</Table.HeaderCell>
                        <Table.Cell className={bestPlan === "A" ? "best" : ""} >{PlanA.metDeducatable}</Table.Cell>
                        <Table.Cell className={bestPlan === "B" ? "best" : ""} >{PlanB.metDeducatable}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Deductable Paid</Table.HeaderCell>
                        <Table.Cell className={bestPlan === "A" ? "best" : ""} >{formatter.format(PlanA.deductablePaid)}</Table.Cell>
                        <Table.Cell className={bestPlan === "B" ? "best" : ""} >{formatter.format(PlanB.deductablePaid)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Deductable Remaining</Table.HeaderCell>
                        <Table.Cell className={bestPlan === "A" ? "best" : ""} >{formatter.format(PlanA.remainingDeductable)}</Table.Cell>
                        <Table.Cell className={bestPlan === "B" ? "best" : ""} >{formatter.format(PlanB.remainingDeductable)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Total Co-Insurance Cost </Table.HeaderCell>
                        <Table.Cell className={bestPlan === "A" ? "best" : ""} >{formatter.format(PlanA.coInsuranceCost)}</Table.Cell>
                        <Table.Cell className={bestPlan === "B" ? "best" : ""} >{formatter.format(PlanB.coInsuranceCost)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Out of Pocket Cost (Deductable + CoInsurance) </Table.HeaderCell>
                        <Table.Cell className={bestPlan === "A" ? "best" : ""} >{formatter.format(PlanA.outOfPocket)}</Table.Cell>
                        <Table.Cell className={bestPlan === "B" ? "best" : ""} >{formatter.format(PlanB.outOfPocket)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="totals">Total Cost (Premium + Out-of-Pocket)</Table.HeaderCell>
                        <Table.Cell className={bestPlan === "A" ? "best" : ""} ><Header className="totals">{formatter.format(PlanA.totalCost)}</Header></Table.Cell>
                        <Table.Cell className={bestPlan === "B" ? "best" : ""} ><Header className="totals">{formatter.format(PlanB.totalCost)}</Header></Table.Cell>
                    </Table.Row>
                   
                </table>
        </Segment>

            
        </div>
    )
}

export default HealthInsCalc

