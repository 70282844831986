export {WorkBenifits401k, WorkBenifitsHSA, WorkBenifitsFSA, InsuranceLife, InsuranceHealth};

const WorkBenifits401k = `  
    <ul>
        <li>Max out HSA first</li>
        <li>Minimum contribution of company match</li>
        <li>Max out 401k contribution</li>
        <li>If you have options for Roth & Traditional contributions </li>  
            <ul>
                <li> Guessing game of "Will my income tax be higher now or later?"</li>
                <li> Tax brackets may change in the future making it a bit of "predicting the future"</li>
                <li> If income tax would be the EXACT same as today vs when you withdrawl, then it really wouldnt make a difference</li>
                <li> If your a high income earner now its more likely TRADITIONAL would be best as taxes are DEFERRED</li>
                <li> If your a lower income earner now its more likely ROTH would be best as taxes are paid NOW/Upfront</li>
            </ul> 
    </ul>
   
`
const WorkBenifitsHSA = `  
    <ul>
        <li>Max out before 401k</li>
        <li>Unlike FSA money rolls over (NOT use it or loose it)</li>
            <ul>
                <li> Max contribution limit is pretty low though compared to 401k, IRA etc</li>
            </ul> 
        <li>Make sure money in HSA can be invested</li>
        <li>Make sure the money in your HSA IS invested</li>  
        <li>Dont use this money, rather pay out of pocket for medical expense and save/compound this tax free account</li>  
        <li>Compounding intrest</li> 
        <li>Rare tripple tax savings (no tax on input, no tax on earnings, no tax on payment if for medical cost)</li> 
    </ul>
   
`
const WorkBenifitsFSA = `  
    <ul>
        <li>There are FSA "Health Care" and FSA "Dependent Care" accounts</li>
        <li>Money is "Use it or Loose it" at the end of the year</li>
            <ul>
                <li> For FSA Health Care accounts, lots of items apply such as contacts, OTC Medicine, Tampons etc</li>
            </ul> 
        <li>Can has HSA AND FSA "Dependent Care" Accounts (recommended if you have kids)</li>  
        <li>FSA "Dependent Care" allows you to pay a certian ammount of daycare, tax free</li>  
            <ul>
                <li>Can be used to pay friend/relative for child care as well as formal daycare/private school</li>
            </ul>  
    </ul>
   
`
const InsuranceLife = `  
    <ul>
        <li>Many options and can be very complicated</li>
        <li>Ask the question "If I died in the next 'X' years would my loved ones be ok?</li>
            <ul>
                <li>If the answer is "Yes" then no insurance is needed</li>
                <li>If the answer is "No" keep reading</li>
            </ul> 
        <li>Term Life Insurance is recomended and more straight forward </li>
            <ul>
                <li>Select Payout ammount and duration of coverage, pay a monthly fee until death, cancelation of policy or duration of term</li>
                <li>Ex. $1,000,000 payout with a term of 25 years. Pay $75 a month fee. After 25 years pass policy is no longer valid</li>
                <li>If the insured dies within the 25 years (and up to date on payments) the ammount is paid to the benificiary</li>
                <li>Or after 25 years pass policy is no longer valid</li>
            </ul> 
        
    </ul>
   
`
const InsuranceHealth = [

    {
        "pageHeading":"Health Insurance"
    },
    {
        "title":"Everyone needs health insurance!"
    },
    {
        "title":"Need to understand...",
        "bullets": [
            {
                "content":"Deductable: Money you will pay until insurance kicks in"
            },
            {
                "content":"CoInsurance: After Deductable is satisfied, what percentage you will be responsible for"
            },
            {
                "content":"Max out of pocket: The MOST you can pay in one policy year"
            },
            {
                "content":"HSA Eligibility: Does this plan qualify me for HSA contirbutions"
            }
        ]
    },
    {
        "title":"Heath Insurance Plan Options",
        "bullets": [
            {
                "content":"When choosing, its a balance of risk as well as trying to predict the future (all insurance)"
            },
            {
                "content":"Typically high deductable with low paycheck cost or low deductable with High paycheck cost... some options inbetween"
            },
            {
                "content":"\"Max out of pocket\" is something to consider"
            },
            {
                "content":"How the insurance breaks up children(s) and spouse's on a policy is another consideration"
            }
        ]
    }

]

  