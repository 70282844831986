import { PROPERTY_TYPES } from '@babel/types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Icon, Image, Menu, Dropdown, Button, Sidebar, Segment, Header, Ref } from 'semantic-ui-react';
import Footer from './Footer';
import logo from './Images/BulletedLifeLogoSmall.png'
import './index.css'
import Background from './Images/calcBG.jpg';




const Navbar = (props) => {
  const segmentRef = React.useRef()
  const [visable, setVisable] = useState(false);
  const [navbar, setNavbar] =  useState(false);

  useEffect(() => {

  }, [visable]);

  const handleClick = () => {
    if(visable === true){
      setVisable(false);
    }
    else{
      setVisable(true);
    }
  }

  const handleLinkClick = () => {
      setVisable(false);
  }

  const changeNav = () =>{
    if(window.scrollY >= 32){
      setNavbar(true);
    }
    else{
      setNavbar(false);
    }
  }

  window.addEventListener('scroll', changeNav)

  return (
    <div >
     <div id="mobile" style={{}}>
      {/* Navbar for Mobile */}
        <Segment className={navbar ? 'navbar active' : 'navbar'} size="tiny" attached   style={{position: "fixed", height: "70px", zIndex:"1", border:0}}>
          <Button id="dropdown" size="huge" icon="sidebar" onClick={handleClick} />
          <Link to='/' onClick={handleLinkClick}>
            <Image src={logo} floated="right" style={{width:"15%"}} />  
          </Link>
        </Segment>
        <Sidebar.Pushable style={{marginTop:"69px", transform: "none"}}>
          <Sidebar
            className={navbar ? 'active' : ''}
            as={Menu}
            animation='push'
            icon='labeled'
            direction='left'
            inverted
            vertical
            visible={visable}
        
            target={segmentRef}
            onHide={() => setVisable(false)}
            style={{position: "fixed", top:"69px", zIndex:"2"}}
            onClick={handleLinkClick}
          >
            <Menu.Item>
              <Dropdown className="MobileDropdown"  item  text="Home Ownership" icon={null}  direction="left" compact>
                <Dropdown.Menu direction="left">
                  <Dropdown.Item><Link to="/HomeOwnership/Buying">Buying</Link></Dropdown.Item>
                  <Dropdown.Item><Link to="/HomeOwnership/Selling">Selling</Link></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            <Menu.Item>
              <Dropdown className="MobileDropdown"  item  text="Work Benifits" icon={null}  direction="left" compact>
                <Dropdown.Menu direction="left">
                  <Dropdown.Item><Link to="/WorkBenifits/401k">401k</Link></Dropdown.Item>
                  <Dropdown.Item><Link to="/WorkBenifits/HSA">HSA</Link></Dropdown.Item>
                  <Dropdown.Item> <Link to="/WorkBenifits/FSA">FSA</Link></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            <Menu.Item>
              <Dropdown className="MobileDropdown"  item  text="Insurance" icon={null}  direction="left" compact>
                <Dropdown.Menu direction="left">
                  <Dropdown.Item> <Link to="/Insurance/Life">Life Insurance</Link></Dropdown.Item>
                  <Dropdown.Item> <Link to="/Insurance/Health">Health Insurance</Link></Dropdown.Item>
                  <Dropdown.Item> <Link to="/Insurance/Disability">Disability</Link></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            <Menu.Item>
              <Dropdown className="MobileDropdown"  item  text="Investing" icon={null}  direction="left" compact>
                <Dropdown.Menu direction="left">
                  <Dropdown.Item> <Link to="/Investing/Stocks">Stocks</Link></Dropdown.Item>
                  <Dropdown.Item> <Link to="/Investing/Realestate">Realestate</Link></Dropdown.Item>
                  <Dropdown.Item> <Link to="/Investing/Other">Other</Link></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            <Menu.Item>
              <Dropdown className="MobileDropdown"  item  text="Life" icon={null}  direction="left" compact>
                <Dropdown.Menu direction="left">
                  <Dropdown.Item> <Link to="/Life/Principles">Principles</Link></Dropdown.Item>
                  <Dropdown.Item> <Link to="/Life/Goals">Goals</Link></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            <Menu.Item>
              <Dropdown className="MobileDropdown"  item  text="Tools" icon={null}  direction="left" compact>
                <Dropdown.Menu direction="left">
                  <Dropdown.Item> <Link to="/Tools/HealthInsurancePlanComparison">Health Insurance Plan Comparison</Link></Dropdown.Item>
                  <Dropdown.Item> <Link to="/Tools/TaxBracketCalc">Tax Bracket Calculator</Link></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            <Menu.Item><Link className="About" to="/About">About</Link></Menu.Item>

          </Sidebar>

          <Sidebar.Pusher style={{position:"inherit"}}>
            
            <Ref innerRef={segmentRef}>
              <div>
              <Container style={{ marginTop: '1.5em', marginBottom: '5em' }}>
                {props.children}
              </Container>
              </div>
            </Ref>
          </Sidebar.Pusher>
          </Sidebar.Pushable>
      </div>

      {/* Navbar for Desktop */}
      <div id="desktop" >
      <Menu id="navbar" className="navbar" color="black" inverted fixed="top" borderless  >
          <Menu.Item className="test" header>
          <Link to='/'>
            <Image src={logo} size='tiny' href='/' /> 
          </Link>
          </Menu.Item>
        
            <Dropdown  item text='Home Ownership' icon={null} pointing>
              <Dropdown.Menu>
                <Dropdown.Item><Link className="linkFull" to="/HomeOwnership/Buying">Buying</Link></Dropdown.Item>
                <Dropdown.Item><Link className="linkFull" to="/HomeOwnership/Selling">Selling</Link></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
         
            <Dropdown item text='Work Benifits' icon={null} pointing>
              <Dropdown.Menu>
                <Dropdown.Item><Link className="linkFull" to="/WorkBenifits/401k">401k</Link></Dropdown.Item>
                <Dropdown.Item><Link className="linkFull" to="/WorkBenifits/HSA">HSA</Link></Dropdown.Item>
                <Dropdown.Item><Link className="linkFull" to="/WorkBenifits/FSA">FSA</Link></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
       
            <Dropdown item text='Insurance' icon={null} pointing>
              <Dropdown.Menu>
                <Dropdown.Item><Link className="linkFull" to="/Insurance/Life">Life Insurance</Link></Dropdown.Item>
                <Dropdown.Item><Link className="linkFull" to="/Insurance/Health">Health Insurance</Link></Dropdown.Item>
                <Dropdown.Item><Link className="linkFull" to="/Insurance/Disability">Disability</Link></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown item text='Investing' icon={null} pointing>
              <Dropdown.Menu>
                <Dropdown.Item><Link className="linkFull" to="/Investing/Stocks">Stocks</Link></Dropdown.Item>
                <Dropdown.Item><Link className="linkFull" to="/Investing/Realestate">Realestate</Link></Dropdown.Item>
                <Dropdown.Item><Link className="linkFull" to="/Investing/Other">Other</Link></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
         
            <Dropdown item text='Life' icon={null} pointing>
              <Dropdown.Menu>
                <Dropdown.Item><Link className="linkFull" to="/Life/Principles">Principles</Link></Dropdown.Item>
                <Dropdown.Item><Link className="linkFull" to="/Life/Goals">Goals</Link></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown item text='Tools' icon={null} pointing>
              <Dropdown.Menu>
                <Dropdown.Item><Link className="linkFull" to="/Tools/HealthInsurancePlanComparison">Health Insurance Plan Comparison</Link></Dropdown.Item>
                <Dropdown.Item><Link className="linkFull" to="/Tools/TaxBracketCalc">Tax Bracket Calculator</Link></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item><Link className="About" to="/About">About</Link></Menu.Item>

      </Menu>
      <Container style={{ marginTop: '5em', marginBottom: '5em'}}>
        <div style={{paddingTop: '60px'}}>
          {props.children}
        </div>
      </Container>
      </div>
    </div>
  );
};
export default Navbar;